import React from "react"
import NoheaderFooter from "../../../../components/Noheaderfooter"
import SEO from "../../../../components/seo"
import PolicyMobileSidebar from "../../../../components/body/pages/mobile/policy-sidebar"

const Legal = () => (
  <NoheaderFooter>
    <SEO
      canonical={"https://kuda.com/en-gb/legal/"}
      title="Legal | Kuda | The Money App for Africans"
    />
    <PolicyMobileSidebar />
  </NoheaderFooter>
)

export default Legal
